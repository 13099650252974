import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import "./Footer.scss";
import HotelLogo from "../../assets/images/logo.png";

const FooterView = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if(location.pathname === "/play"){
      setIsVisible(false);
    }
    else setIsVisible(true);

  }, [location]);

  return (
    <>
    {isVisible &&
    <>
        <div className="col-span-12 flex flex-col items-center justify-center gap-1 relative top-4 mb-8">
          <p className='text-white text-opacity-70 text-sm'>Copyright © 2024 HabboAL Hotel</p>
          <div className="flex items-center gap-2 text-white text-opacity-70 text-[11px]">
            <p>Impressum</p>
            <p>Regelwerk</p>
            <p>Kontaktiere Uns</p>
          </div>
          <div className="text-opacity-70 text-xs text-white">
            HabboAL CMS Coded by Lariesse & Mori
          </div>
        </div>
    </>
    }
    
    </>
  )
}

export default FooterView
