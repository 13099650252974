class Data{
    username: string;

    constructor(username: string){
        this.username = username;
    }
}

export class GetProfileComposer{
    header: string;
    data: Data;

    constructor(username: string){
        this.header = "getProfile";
        this.data = new Data(username);
    }
}