import React, { useEffect, useState } from "react";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import { useNavigate, useParams, Link } from "react-router-dom";

import LoadingView from "../loading/LoadingView";
import { CommunityComposer } from "../../packets/CommunityComposer";

import CommunityTop from "./CommunityTop";
import CommunityRoomTop from "./CommunityRoomTop";
import CommunityPhoto from "./CommunityPhoto";
import CommunityGroupTop from "./CommunityGroupTop";
import { LocalizeText } from "../../Globals";

const CommunityView = (props) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [socketUrl, setSocketUrl] = useState("wss://ws.habbo.al:2087");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    share: true,
  });
  const navigate = useNavigate();
  const params = useParams();

  const [section, setSection] = useState("0");
  const [loading, setLoading] = useState(true);
  const [achievementScore, setAchievementScore] = useState<any>(null);
  const [RespectEarned, setRespectEarned] = useState<any>(null);
  const [RespectGiven, setRespectGiven] = useState<any>(null);
  const [AllTimeHotelPresence, setAllTimeHotelPresence] = useState<any>(null);
  const [RoomEntry, setRoomEntry] = useState<any>(null);
  const [RoomDecoFurniCount, setRoomDecoFurniCount] = useState<any>(null);
  const [BattleBallWinner, setBattleBallWinner] = useState<any>(null);
  const [FreezeWinner, setFreezeWinner] = useState<any>(null);
  const [FootballGoalScored, setFootballGoalScored] = useState<any>(null);
  const [roomsPopulars, setRoomsPopulars] = useState<any>(null);
  const [roomsScores, setRoomsScores] = useState<any>(null);
  const [kubbitos, setKubbitos] = useState<any>(null);
  const [diamonds, setDiamonds] = useState<any>(null);
  const [credits, setCredits] = useState<any>(null);
  const [duckets, setDuckets] = useState<any>(null);
  const [photos, setPhotos] = useState<any>(null);
  const [gamePoints, setGamePoints] = useState<any>(null);
  const [groups, setGroups] = useState<any>(null);

  const selectSection = (section) => {
    if (section === null) setSection("0");
    else setSection(section);
  };

  var isLoaded = false;
  useEffect(() => {
    if (isLoaded) return;
    isLoaded = true;

    document.title = "Community | Habbo";

    if (params === null || params.type === null)
      navigate("/me", { replace: true });
    selectSection(params.type);
    sendMessage(JSON.stringify(new CommunityComposer()));
  }, []);

  useEffect(() => {
    if (lastMessage !== null) {
      var message = JSON.parse(lastMessage.data);

      if (message.header === "topsStats" && message.data !== null) {
        setAchievementScore(JSON.parse(message.data.AchievementScore));
        setRespectEarned(JSON.parse(message.data.RespectEarned));
        setRespectGiven(JSON.parse(message.data.RespectGiven));
        setAllTimeHotelPresence(JSON.parse(message.data.AllTimeHotelPresence));
        setRoomEntry(JSON.parse(message.data.RoomEntry));
        setRoomDecoFurniCount(JSON.parse(message.data.RoomDecoFurniCount));
        setBattleBallWinner(JSON.parse(message.data.BattleBallWinner));
        setFreezeWinner(JSON.parse(message.data.FreezeWinner));
        setFootballGoalScored(JSON.parse(message.data.FootballGoalScored));
        setPhotos(JSON.parse(message.data.photos));
        setRoomsPopulars(JSON.parse(message.data.roomsPopulars));
        setRoomsScores(JSON.parse(message.data.roomsScores));
        setKubbitos(JSON.parse(message.data.currency));
        setDiamonds(JSON.parse(message.data.diamonds));
        setCredits(JSON.parse(message.data.credits));
        setDuckets(JSON.parse(message.data.duckets));
        setGamePoints(JSON.parse(message.data.points));
        setGroups(JSON.parse(message.data.groups));

        setLoading(false);
      }
    }
  }, [lastMessage, setMessageHistory]);

  const changeSection = (section) => {
    setLoading(true);
    navigate("/community/" + 0, { replace: true });
    setSection(section);
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <LoadingView />
      ) : (
        <div className="max-w-6xl mx-auto gap-4 w-full grid grid-cols-12 container-dashboard animate__animated animate__fadeIn animate__slower mt-5">
            <div className="col-span-12 h-32 bg-white whiteBackground flex flex-col items-center justify-center relative overflow-hidden">
                <div className="vipImageLeft hidden md:block"></div>
                <p className='text-4xl uppercase poppins-bold text-quarternary tracking-widest mt-2 text-center md:text-start'>Habbo Bestenliste</p>
                <div className="w-96 h-[2px] bg-black"></div>
                <p className='text-quarternary poppins-bold text-center md:text-start'>Sehen Sie sich an, wer <span className='text-[#5aa488] mr-1 ml-1'>REICH</span> ist?</p>
              </div>
          {section === "0" && (
            <>
              <div className="flex flex-col col-span-12 md:col-span-4 bg-[#fcfcfc] ">
                <div className="rounded text-quarternary poppins-bold p-4">
                  {LocalizeText(props.texts, "community.top3") +
                    LocalizeText(props.texts, "community.top.currency")}
                </div>
                <hr className="m-0 -mt-2" />
                <div className=" px-2 mt-2 w-full mb-2">
                  {kubbitos.slice(0, 5).map((keko) => (
                    <CommunityTop
                      key={Math.random()}
                      look={keko.look}
                      username={keko.username}
                      topName={LocalizeText(props.texts, "global.currency")}
                      amount={keko.amount}
                    />
                  ))}
                </div>
              </div>

              <div className="flex flex-col col-span-12 md:col-span-4 bg-[#fcfcfc] ">
                <div className="text-quarternary poppins-bold p-4 rounded">
                  {LocalizeText(props.texts, "community.top3") +
                    LocalizeText(props.texts, "community.top.diamonds")}
                </div>
                <hr className="m-0 -mt-2" />
                <div className=" px-2 mt-2 w-full mb-2">
                  {diamonds.slice(0, 5).map((keko) => (
                    <CommunityTop
                      key={Math.random()}
                      look={keko.look}
                      username={keko.username}
                      topName={LocalizeText(props.texts, "global.diamonds")}
                      amount={keko.amount}
                    />
                  ))}
                </div>
              </div>

              <div className="flex flex-col col-span-12 md:col-span-4 bg-[#fcfcfc] ">
                <div className="text-quarternary poppins-bold p-4 rounded">
                  {LocalizeText(props.texts, "community.top3") +
                    LocalizeText(props.texts, "community.top.credits")}
                </div>
                <hr className="m-0 -mt-2" />
                <div className=" px-2 mt-2 w-full mb-2">
                  {credits.slice(0, 5).map((keko) => (
                    <CommunityTop
                      key={Math.random()}
                      look={keko.look}
                      username={keko.username}
                      topName={LocalizeText(props.texts, "global.credits")}
                      amount={keko.amount}
                    />
                  ))}
                </div>
              </div>

              <div className="flex flex-col col-span-12 md:col-span-4 bg-[#fcfcfc] ">
                <div className="text-quarternary poppins-bold p-4 rounded">
                  {LocalizeText(props.texts, "community.top3") +
                    LocalizeText(props.texts, "community.top.score")}
                </div>
                <hr className="m-0 -mt-2" />
                <div className=" px-2 mt-2 w-full mb-2">
                  {achievementScore.slice(0, 5).map((keko) => (
                    <CommunityTop
                      key={Math.random()}
                      look={keko.account.look}
                      username={keko.account.username}
                      topName={LocalizeText(
                        props.texts,
                        "community.global.score"
                      )}
                      amount={keko.stats}
                    />
                  ))}
                </div>
              </div>

              <div className="flex flex-col col-span-12 md:col-span-4 bg-[#fcfcfc] ">
                <div className="text-quarternary poppins-bold p-4 rounded">
                  {LocalizeText(props.texts, "community.top3") +
                    LocalizeText(props.texts, "community.top.respects")}
                </div>
                <hr className="m-0 -mt-2" />
                <div className=" px-2 mt-2 w-full mb-2">
                  {RespectEarned.slice(0, 5).map((keko) => (
                    <CommunityTop
                      key={Math.random()}
                      look={keko.account.look}
                      username={keko.account.username}
                      topName={LocalizeText(
                        props.texts,
                        "community.global.respects"
                      )}
                      amount={keko.stats}
                    />
                  ))}
                </div>
              </div>

              <div className="flex flex-col col-span-12 md:col-span-4 bg-[#fcfcfc] ">
                <div className="text-quarternary poppins-bold p-4 rounded">
                  {LocalizeText(props.texts, "community.top3") +
                    LocalizeText(props.texts, "community.top.online")}
                </div>
                <hr className="m-0 -mt-2" />
                <div className=" px-2 mt-2 w-full mb-2">
                  {AllTimeHotelPresence.slice(0, 5).map((keko) => (
                    <CommunityTop
                      key={Math.random()}
                      look={keko.account.look}
                      username={keko.account.username}
                      topName={LocalizeText(
                        props.texts,
                        "community.global.online"
                      )}
                      amount={keko.stats}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CommunityView;
