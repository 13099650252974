import React, { useEffect, useState } from 'react';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { Link } from 'react-router-dom';

import "./Article.scss"
import LoadingView from '../loading/LoadingView';

import { NewsComposer } from '../../packets/NewsComposer';
import { getArticleRoute, timeSince, LocalizeText } from '../../Globals';


const ArticlesView = (props) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [socketUrl, setSocketUrl] = useState("wss://ws.habbo.al:2087");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });

  const [news, setNews] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  var isLoaded = false;
  useEffect(() => {
    if(isLoaded) return;
    isLoaded = true;
    document.title = LocalizeText(props.texts, 'article.title') + " | " + LocalizeText(props.texts, 'global.hotelname')
    sendMessage(JSON.stringify(new NewsComposer()))
  }, [])

  useEffect(() => {
    if (lastMessage !== null) {
        var message = JSON.parse(lastMessage.data);

        if(message.header === "newsList" && message.data !== null) setNews(JSON.parse(message.data.data));
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    if(news !== null) setLoading(false)
  }, [news])

  return (
    <>
      { loading ? <LoadingView /> :(
        <>
          <div className='max-w-6xl gap-4 w-full mx-auto grid grid-cols-12 animate__animated animate__fadeIn animate__slower mt-5'>
              <div className="col-span-12 grid grid-cols-12 gap-4">
                {news !== null && news.length !== 0 &&
                  <>
                    {news.map((article) => 
                      <div key={article.id} className="col-span-12 md:col-span-4 bg-[#f9f9f9] group">
                          <div className="h-48 !bg-center flex items-center justify-center" style={{ backgroundImage: `url(${article.header})` }}>
                            <Link to={getArticleRoute(article.id, article.title)} className="py-2 px-5 bg-[#f9f9f9] rounded-md text-quarternary top-2 group-hover:-translate-y-2 opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto transition duration-300">{LocalizeText(props.texts, 'article.view.button')}</Link>
                          </div>
                          <div className="p-2 bg-[#f9f9f9] flex flex-col truncate">
                            <p className='poppins-bold text-quarternary text-lg truncate'>
                              {article.title}
                            </p>
                            <p className='text-sm text-quarternary truncate text-opacity-70'>
                              {article.shortStory}
                            </p>
                            <div className="flex mt-0.5 justify-between items-center text-xs text-quarternary">
                              <p>{article.author.username}</p>
                              <p>{timeSince(article.date)}</p>
                            </div>
                          </div>
                      </div>
                    )}
                  </>
                }
              </div>
          </div>
        </>
      )}
    </>
  )
}

export default ArticlesView