export const CATEGORY_RARE = "Rares";
export const CATEGORY_LTD = "LTD";
export const CATEGORY_CRACKABLE = "Crackable";
export const CATEGORY_CUSTOM = "Custom";
export const SCHWARZMARKT_RARE = "Schwarzmarkt Rare";
export const CASINO_RARE = "Casino Rare";
export const GIVEAWAY_RARE= "Giveaway Rare";
export const WOCHENRARE = "Wochenrare";
export const EVENTPUNKTE_RARE = "Eventpunkte Rare";
export const BATTLEPASS_RARE = "Battlepass Rare";
export const SONSTIGE_RARE = "Sonstige Rare";
export const BONZEN_RARE = "Bonzen Rarität";
export const CASH_RARE = "Cash Rare";
export const CATEGORY_NONE = "";