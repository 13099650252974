import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom';

const PlayView = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
        document.title = "Hotel | Habbo"
      if(props.account === null) navigate("/index", { replace: true });
  }, [])


  return (
    <>

    </>
  )
}

export default PlayView
