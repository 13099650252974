import { FC, useEffect, useState } from "react";
import ModalCatalogItemView from "./ModalCatalogItemView";

interface catalogItemProps {
    item: any;
    canEdit: boolean;
}

const CatalogItemView: FC<catalogItemProps> = props => {
    const { item = [], canEdit = false } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);



    return (
        <div key={item.id} className="col-span-12 md:col-span-4 relative">
            <div className="w-full bg-[#f9f9f9] p-1 flex flex-col gap-1">
                <div className="flex items-center justify-between gap-2">
                    <div className="w-full p-2 bg-quarternary flex items-center justify-center backdrop-blur-xl text-white gap-1">
                        <div className='catalog-furni-price'>{item.price_credits == 1 ? "Unbekannt" : item.price_credits}</div>
                        <img src="https://images.habbo.one/images/wallet/-1.png" />
                    </div>
                    <div className="w-full p-2 bg-quarternary flex items-center justify-center backdrop-blur-xl text-white gap-1">
                        <div className='catalog-furni-price'>{item.price_diamonds == 1 ? "Unbekannt" : item.price_diamonds}</div>
                        <img src="https://images.habbo.one/images/wallet/5.png" />
                    </div>
                </div>
                <div className="catalog-furni-card">
                    <img className='catalog-furni-image' src={item.image} />
                </div>
                <div className="p-2 bg-quarternary text-center">
                    {item.name}
                </div>
            </div>
        </div>
    );
}

export default CatalogItemView;
