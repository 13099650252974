class Sso{
    sso: string;

    constructor(sso: string){
        this.sso = sso;
    }
}

export class AuthComposer{
    header: string;
    data: Sso;

    constructor(sso: string){
        this.header = "auth";
        this.data = new Sso(sso);
    }
}