class UpdatePasswordInfo{
    passwordActual: string;
    password: string;
    rPassword: string;

    constructor(passwordActual: string, password: string, rPassword: string){
        this.passwordActual = passwordActual;
        this.password = password;
        this.rPassword = rPassword;
    }
}

export class UpdatePasswordComposer{
    header: string;
    data: UpdatePasswordInfo;

    constructor(passwordActual: string, password: string, rPassword: string){
        this.header = "changePassword";
        this.data = new UpdatePasswordInfo(passwordActual, password, rPassword);
    }
}