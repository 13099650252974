import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import Draggable from 'react-draggable';
import "./Profile.scss";
import { getPreviewImage, getImagerForProfile, timeSince, getBadgeUrl, getImagerForTops, getImagerForTopsProfile, LocalizeText } from '../../Globals';
import { useNavigate, useParams, Link } from 'react-router-dom';

import Sticker from './Sticker';
import Widget from './Widget';
import Note from './Note';

import { NotificationManager } from 'react-notifications';

import { GetProfileComposer } from '../../packets/GetProfileComposer';
import { UpdateProfileComposer } from '../../packets/UpdateProfileComposer';
import { DeleteTweetComposer } from '../../packets/DeleteTweetComposer';

import LoadingView from '../loading/LoadingView';



const ProfileView = (props, { match }) => {
    const [loading, setLoading] = useState(true);

    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://ws.habbo.al:2087");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
    const navigate = useNavigate();
    const [badges, setBadges] = useState<any>(null);
    const [friends, setFriends] = useState<any>(null);
    const params = useParams();

    const [account, setAccount] = useState<any>(null);





    const getParamName = (param) => {
        if (param === null) return "no";
        else return param;
    }

    var isLoaded = false;
    useEffect(() => {
        if (isLoaded) return;
        isLoaded = true;

        if (params === null || params.name === null) navigate("/me", { replace: true })
        sendMessage(JSON.stringify(new GetProfileComposer(getParamName(params.name))))
    }, [])

    useEffect(() => {
        if (lastMessage !== null) {
            var message = JSON.parse(lastMessage.data);

            if (message.header === "profileData" && message.data !== null) {
                if (message.data.data !== null && message.data.data === "Este usuario no existe") navigate("/me", { replace: true })
                else {
                    setAccount(JSON.parse(message.data.account));
                    setBadges(JSON.parse(message.data.badges));
                    setFriends(JSON.parse(message.data.friends));
                    if (message.data.style !== null) {
                        const style = JSON.parse(message.data.style);
                    }
                    setLoading(false);
                }
            }

        }
    }, [lastMessage, setMessageHistory]);


    const profileLink = (name) => {
        setLoading(true);
        sendMessage(JSON.stringify(new GetProfileComposer(name)))
        navigate("/profile/" + name, { replace: true })
    }




    return (
        <>
            {loading ? <LoadingView /> : (
                <>
                    <div className='animate__animated gap-2 max-w-6xl w-full grid grid-cols-12 mx-auto animate__fadeIn animate__slower mt-5'>
                        <div className="col-span-12 p-1 bg-[#f9f9f9]">
                            <div className="h-32 bg-white whiteBackground flex flex-col items-center justify-center relative overflow-hidden">
                                <div className="vipImageLeft hidden md:block"></div>
                                <p className='text-4xl uppercase poppins-bold text-quarternary tracking-widest mt-2 text-center md:text-start z-10'>PROFILE VON {account.username}</p>
                                <div className="w-96 h-[2px] bg-black"></div>
                                <p className='text-quarternary poppins-bold text-center md:text-start z-10'>Wilkommen im<span className='text-[#5aa488] mr-1 ml-1'>MEINE</span>profile.</p>
                            </div>
                        </div>
                        <div className="col-span-12 flex flex-col">
                            <div className="h-52 profileBg relative">
                                <div className="absolute h-full w-full bg-black bg-opacity-50"></div>
                            </div>
                            <div className="p-2 w-full flex z-20 items-center justify-center flex-col bg-[#f9f9f9]">
                                <div className="h-40 w-40 -mt-24  profileBg border-2 border-[#f9f9f9] mx-auto flex items-center justify-center overflow-hidden" >
                                    <img src={getImagerForProfile(account.look)} className="mt-5" />
                                </div>
                                <div className="flex flex-col gap-1 items-center justify-center mt-1">
                                    <p className='text-xl poppins-bold text-quarternary'>{account.username}</p>
                                    <p className='text-quarternary italic text-opacity-50'>{account.motto}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-span-12 flex flex-col gap-2 bg-[#fcfcfc]'>
                            <div className="flex items-center p-3 text-lg gap-1 text-quarternary poppins-bold">
                                <div className="profileBadge"></div>
                                {account.username} Badges
                            </div>
                            <hr className='m-0 -mt-4 mb-2' />
                            <div className="grid grid-cols-12 gap-4 px-3 mb-3">
                                {badges === null || badges.length === 0 ? <div className='col-span-12'><div className='alert bg-danger text-white w-100'>{account.username} {LocalizeText(props.texts, 'profile.no.badges')}</div></div> : (
                                    <>
                                        {badges.map((badge) =>
                                            <div className='col-span-2'>
                                                <div style={{ background: getPreviewImage(getBadgeUrl(badge)), backgroundPosition: "center", backgroundRepeat: "no-repeat" }} className="w-100 profile-badge-img"></div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className='col-span-12 flex flex-col gap-2 bg-[#fcfcfc]'>
                            <div className="flex items-center p-3 text-lg gap-1 text-quarternary poppins-bold">
                                <div className="profileBadge"></div>
                                {account.username} Freundes
                            </div>
                            <hr className='m-0 -mt-4 mb-2' />
                            <div className="grid grid-cols-12 gap-4 px-3 mb-3">
                                {friends === null || friends.length === 0 ? <div className='col-span-12'><div className='alert bg-danger text-white w-100'>{account.username} {LocalizeText(props.texts, 'profile.no.friends')}</div></div> : (
                                    <>
                                        {friends.map((friend) =>
                                            <>
                                                <div className="col-span-12 md:col-span-2 lg:col-span-1 w-full p-2 triangleOnlineBoxes group shadow-xl h-20 flex items-center justify-center overflow-hidden">
                                                    <img className='mt-4 absolute' src={getImagerForTopsProfile(friend.look)} />
                                                    <button onClick={() => profileLink(friend.username)} className="group-hover:opacity-100 group-hover:pointer-events-auto opacity-0 transition-all ease-in-out duration-400 group-hover:-translate-y-2 mt-2 pointer-events-none absolute p-2 bg-quarternary">
                                                        {friend.username}
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                </>
            )}
        </>
    )
}


export default ProfileView
