import React, { useEffect, useRef, useState } from 'react'

interface PhotoHeaderProps{
    photos: {[key: string] : string}
}

const PhotoHeaderView = (props: PhotoHeaderProps) => {
  const [photo, setPhoto] = useState<any>(null);
  const photoDisplay = useRef<HTMLInputElement>(null);
  const [int, setInt] = useState<NodeJS.Timer>();

  useEffect(() => {
    setPhoto(props.photos[Math.floor(Math.random()*Object.entries(props.photos).length)]);
    if(int !== null){
      const intId = setInterval(() => {
          if(photoDisplay !== null){
              photoDisplay.current?.classList.remove("animate__animated")
              photoDisplay.current?.classList.remove("animate__fadeIn")

              photoDisplay.current?.classList.add("animate__animated")
              photoDisplay.current?.classList.add("animate__fadeOut")

              setTimeout(() => {
                  setPhoto(props.photos[Math.floor(Math.random()*Object.entries(props.photos).length)]);
              }, 1000);
          }
      }, 5000);

      setInt(intId);
    } else {
      clearInterval(int);
      const intId = setInterval(() => {
          if(photoDisplay !== null){
              photoDisplay.current?.classList.remove("animate__animated")
              photoDisplay.current?.classList.remove("animate__fadeIn")

              photoDisplay.current?.classList.add("animate__animated")
              photoDisplay.current?.classList.add("animate__fadeOut")

              setTimeout(() => {
                  setPhoto(props.photos[Math.floor(Math.random()*Object.entries(props.photos).length)]);
              }, 1000);
          }
      }, 5000);

      setInt(intId);
    }
  }, [props.photos]);

  useEffect(() => {
    if(photo !== null && photoDisplay !== null && photoDisplay.current?.classList.contains("animate__fadeOut")){
        setTimeout(() => {
            photoDisplay.current?.classList.remove("animate__animated")
            photoDisplay.current?.classList.remove("animate__fadeOut")

            photoDisplay.current?.classList.add("animate__animated")
            photoDisplay.current?.classList.add("animate__fadeIn")
        }, 400);
    }
  }, [photo]);

  return (
    <div>
      {photo !== null &&
        <div style={{position: "relative", zIndex: "99999"}} ref={photoDisplay}>
           <div className='header-photo animate__animated animate__fadeIn -mt-5' style={{backgroundImage: `url('${photo.url}')`, top: "24px", float: "right"}}>
                <div className='w-full description-photo relative'>
                    <div className="flex justify-between bg-primary p-2 items-center">
                        <div style={{marginTop: "5px"}}>
                            <span className='badge bg-dark'><b>Foto von {photo.account.username}</b></span>
                        </div>
                        <div>
                            <div style={{marginTop: "-10px", width: '50px', height: '45px', overflow: "hidden", position: "relative", borderRadius: "50%", backgroundColor: '#1c2025', padding: "26px", border: `1px solid #fff`}}>
                                <div style={{backgroundImage: `url(https://nitro-imager.habbo.cm/?figure=${photo.account.look}&head_direction=4&direction=4&gesture=sml)`, marginLeft: '-48px', marginTop: '-34px', backgroundRepeat: "no-repeat", width: '90px', height: '130px', backgroundPosition: "center -8px", position: "absolute", zIndex: "99999999"}} />
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
      }
    </div>
  )
}

/*{"id":5,"account":{"id":85,"username":"RainerZufall","motto":"-","look":"ch-1763-4002-30.he-3070-4005.sh-3089-4002.ha-8241-4000-4000.cp-5994166-92.hd-180-1024.hr-933-4000.lg-281-4002","gender":"M","diamonds":100,"currency":0},"roomId":222,"date":1672232084,"url":"https://images.habbo.one/c_images/camera/85_1672232079.png"}*/

export default PhotoHeaderView
