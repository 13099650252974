import React from 'react'
import { getImagerForTops, getProfileUrl } from '../../Globals'
import { Link } from 'react-router-dom'

const CommunityTop = (props) => {
  return (
    <div className="h-28 hover:-translate-y-1 transition-all mb-3 border-[1px] sqImage relative border-quarternary border-opacity-50 overflow-hidden flex">
      <div className="min-w-32 absolute h-full flex items-center max-w-32 -ml-3">
        <div className='!w-[128px] h-[220px] mt-12' style={{ backgroundImage: getImagerForTops(props.look) }}/>
      </div>
        <div className="flex flex-col p-3 justify-center items-center w-full h-full ml-10">
          <p>
            <Link className='no-deco text-quarternary text-2xl' to={getProfileUrl(props.username)}>{props.username}</Link>
          </p>
          <div className="h-[1.5px] w-44 bg-black mb-2"></div>
            <div className="text-xs italic text-quarternary text-opacity-60" style={{ whiteSpace: "pre-line" }}>{props.amount} {props.topName}</div>
        </div>
    </div>
  )
}

export default CommunityTop
