export const timeSince = (date: number) => {
    var seconds = Math.floor((new Date().valueOf() - new Date(date * 1000).valueOf()) / 1000);
    var interval = seconds / 31536000;
  
    if (interval > 1) return Math.floor(interval) + " Jahren";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " Monaten";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + " Tagen";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + " Stunden";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " Minuten";

    return Math.floor(seconds) + " Sekunden";
}

export const getImagerForTops = (look) => "url(https://nitro-imager.habbo.cm/?figure="+look+"&headonly=0&head_direction=3&gesture=sml&action=wav&size=l)"; 
export const getImagerForTopsProfile = (look) => "https://nitro-imager.habbo.cm/?figure="+look+"&headonly=0&head_direction=2&gesture=sml&action=wav)"; 
export const getImagerForProfile = (look) => "https://nitro-imager.habbo.cm/?figure="+look+"&direction=3&head_direction=3&gesture=sml&size=l"; 
export const getNewsBackgroundCss = (url) => "url("+url+")"; 
export const getNewsBackgroundHref = (url) => url;
export const getImagerAuthorLook = (url) => "url(https://nitro-imager.habbo.cm/?figure="+url+"&direction=2&head_direction=2&size=l&img_format=gif)";
export const getNewsImagerAuthorLook = (url) => "url(https://nitro-imager.habbo.cm/?figure="+url+"&direction=2&head_direction=2&size=m&img_format=gif)";
export const getPreviewImage = (image) => "url("+image+")";
export const getReactionIdById = (id) => "reactionDisplay-"+id;
export const getBadgeUrl = (code) => "https://images.habbo.one/c_images/album1584/"+code+".gif";
export const getProfileUrl = (name) => "/profile/"+name;

export const getArticleRoute = (id: number, name: string) => "/article/"+id+"/"+name.replace(/ /g, "-");

export function LocalizeText(texts: {[key: string] : string}, text: string) : string
{
    if(texts === null) return "";
    
    for(const [key, value]  of Object.entries(texts)){
        if(key === text){
            return value;
        }
    }
    
    return text
}