import React from 'react';

function Regeln() {
  return (
    <div className="max-w-7xl mx-auto w-full mt-5 bg-primary p-2 rounded">
      <h1 className="text-center">Regeln</h1>
      <div className="text-center">
        <p className="lead">Bitte beachte die folgenden Regeln:</p>
      </div>
      <div className="">
        <div className="flex flex-col gap-2">
          <p><strong>§1 Verbot von Mitarbeitermanipulation und Verbreitung nachweislich falscher Informationen:</strong><br />
          Es ist streng verboten, einen Mitarbeiter zu belügen oder bewusst falsche Tatsachen darzulegen.</p>
          <p><strong>§2 Untersagte Verhaltensweisen: Beleidigung, fortgesetzte Provokation und Hetze:</strong><br />
          Beleidigungen, wiederholte Provokationen oder Hetzerei gegenüber anderen Nutzern sind inakzeptabel und untersagt.</p>
          <p><strong>§3 Passwortbetrug:</strong><br />
          Das Erschleichen von Accountdaten oder der unbefugte Zugriff auf fremde Accounts durch Passwortbetrug ist streng verboten.</p>
          <p><strong>§4 Verbotene Äußerungen: Sexismus, Rassismus und Diskriminierung:</strong><br />
          Äußerungen, die sexuell, rassistisch oder diskriminierend sind und bestimmte Volksgruppen oder Religionen betreffen, sind in keiner Weise toleriert und strikt untersagt.</p>
          <p><strong>§5 Verbot von unangemessenen Rauminhalten (genau so wie Raumname):</strong><br />
          Die Erstellung von Räumen mit unangemessenem Inhalt ist nicht gestattet und wird konsequent unterbunden.</p>
          <p><strong>§6 Verbot von unpassenden Usernames:</strong><br />
          Die Verwendung eines unangemessenen Usernames ist nicht erlaubt und sollte vermieden werden.</p>
          <p><strong>§7 Verbot von Identitätsbetrug oder irreführender Teamzugehörigkeit:</strong><br />
          Das Nachahmen einer falschen Identität oder das Vortäuschen, ein Staffmitglied zu sein, ist streng untersagt und wird entsprechend geahndet.</p>
        </div>
        <div className="flex flex-col gap-2">
          <p><strong>§8 Fremdwerbung:</strong><br />
          Das Werben für andere Hotels oder das Teilen von Links zu unangemessenen Inhalten ist nicht gestattet und wird als Fremdwerbung betrachtet.</p>
          <p><strong>§9 Multiaccount:</strong><br />
          Die Nutzung von maximal zwei Accounts ist erlaubt, jedoch ist die Regel "Pro IP 2 User" nicht gültig.</p>
          <p><strong>§10 Benutzen von Cheating-Tools:</strong><br />
          Die Verwendung von Programmen, die einen unfairen Vorteil im Spiel verschaffen, ist strikt untersagt.</p>
          <p><strong>§11 Verbot der Veröffentlichung und Verbreitung privater Daten:</strong><br />
          Die Veröffentlichung oder Verbreitung von privaten Kontaktdaten, Bildern oder anderen persönlichen Informationen ist streng verboten und wird mit Konsequenzen geahndet.</p>
          <p><strong>§12 Verbotener Austausch von Echtgeld zwischen Usern:</strong><br />
          Der Handel mit digitalen Gütern gegen Echtgeld zwischen den Usern ist nicht erlaubt und wird als Verstoß gegen die Regeln angesehen.</p>
          <p><strong>§13 Geldwäsche:</strong><br />
          Es ist nicht erlaubt, Taler, Diamanten, Duckets oder Rares durch das Erstellen mehrerer Accounts oder ähnliche Methoden zu vermehren.</p>
          <p><strong>§14 Illegales Glücksspiel:</strong><br />
          Das Durchführen von unerlaubtem Glücksspiel ist untersagt.</p>
        </div>
      </div>
      <div className="text-center mt-5">
        <h3>Allgemeine Bestimmungen:</h3>
        <ul>
          Das Regelwerk ist bindend für alle Nutzer des Habbo Hotels.
          Bei Verstößen gegen das Regelwerk können Maßnahmen ergriffen werden, wie beispielsweise Kontosperrungen oder Verbote.
          Das Regelwerk kann jederzeit aktualisiert werden. Es liegt in der Verantwortung der Nutzer, sich über Änderungen auf dem Laufenden zu halten.
        </ul>
        <h3>Kontakt:</h3>
        <p>Bei Fragen oder Meldungen von Regelverstößen wende dich bitte an unseren Support.</p>
        <footer>
          &copy; 2023 HabboONE. Alle Rechte vorbehalten.
        </footer>
      </div>
    </div>
  );
}

export default Regeln;
