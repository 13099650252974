import React, { useEffect, useRef, useState } from 'react'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';

import "./Register.scss";
import { RegisterComposer } from '../../packets/RegisterComposer';
import { LocalizeText } from '../../Globals';
import { LoginComposer } from '../../packets/LoginComposer';

interface LoginViewProps{
    texts: {[key: string] : string};
  }

const LoginView = (props: LoginViewProps) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [socketUrl, setSocketUrl] = useState("wss://ws.habbo.al:2087");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
  const closeRef =  useRef<HTMLButtonElement>(null);

  const [username, setUsername] =  useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const login = () => {

    if(username == "" || password == ""){
        NotificationManager.error(LocalizeText(props.texts, "login.error.spaces"), "Error");
        return;
    }
    
    sendMessage(JSON.stringify(new LoginComposer(username, password)))
  }

  useEffect(() => {
    if (lastMessage !== null) {
        var message = JSON.parse(lastMessage.data);
        if(message.header === "loginResponse" && message.data != null){
            if(message.data.data === "Contraseña incorrecta!") NotificationManager.error(LocalizeText(props.texts, "login.error.wrongpassword"), "Error");
            else if(message.data.data === "Este usuario no existe!") NotificationManager.error(LocalizeText(props.texts, "login.error.usernotfound"), "Error");
            else if(message.data.data === "Esta cuenta esta baneada") NotificationManager.error(LocalizeText(props.texts, "login.error.banned"), "Error");
            else{
                closeRef.current?.click()
                NotificationManager.success(LocalizeText(props.texts, "login.success.session"), "OK");
                window.localStorage.setItem("kubboch-sto", message.data.data);
                navigate("/me", { replace: true });
            }
        }
    }
  }, [lastMessage, setMessageHistory]);
  return (
    <div className='w-full max-w-md gap-5 mx-auto animate__animated animate__fadeIn bg-primary p-2 mt-5 rounded flex flex-col'>
      <div className="flex items-center gap-2">
        <img src="https://i.imgur.com/Kw9M2EZ.gif" alt="" />
        <div className="flex flex-col gap-1">
          <p className='text-2xl font-extrabold'>Anmelden HabboONE</p>
          <p className='text-white text-opacity-80'>Hast du deine ONE Schlüssel dabei?</p>
        </div>
      </div>
      <div className="w-full h-1 bg-secondary"></div>
      <div className="flex flex-col gap-8">
        <div className="flex-col flex gap-2">
          <p>Benutzername</p>
          <input onChange={(e) => setUsername(e.target.value)} className='jeInput' type="text" placeholder='Benutzername' />
        </div>
        <div className="flex-col flex gap-2">
          <p>Passwort</p>
          <input onChange={(e) => setPassword(e.target.value)} className='jeInput' placeholder='Passwort' type="password" />
        </div>
        <button onClick={() => login()} className='jeHeaderLoginButton font-extrabold w-full p-2 rounded'>Anmelden</button>
      </div>
    </div>
  )
}

export default LoginView
