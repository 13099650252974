import React, { useEffect, useState } from 'react';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { useNavigate, useParams, Link } from 'react-router-dom';

import "./Article.scss"


import { timeSince, getImagerForTops, getNewsBackgroundHref, getArticleRoute, getNewsImagerAuthorLook, getProfileUrl, LocalizeText } from '../../Globals';

import LoadingView from '../loading/LoadingView';
import { ArticleComposer } from '../../packets/ArticleComposer';
import { NewsComposer } from '../../packets/NewsComposer';
import { ArticleCommentComposer } from '../../packets/ArticleCommentComposer';
import { DeleteArticleCommentComposer } from '../../packets/DeleteArticleCommentComposer';
import { NotificationManager } from 'react-notifications';

const ArticleView = (props, { match }) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [socketUrl, setSocketUrl] = useState("wss://ws.habbo.al:2087");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
  const navigate = useNavigate();
  const params = useParams();

  const [article, setArticle] = useState<any>({});
  const [news, setNews] = useState<any[]>([]);
  const [comments, setComments] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState("");

  const getArticleIdByParam = (param) => {
    if (param === null) return 0;
    else return param;
  }

  var isLoaded = false;
  useEffect(() => {
    if (isLoaded) return;
    isLoaded = true;

    if (params === null || params.id === null) navigate("/articles", { replace: true });
    document.title = params.name?.replace("-", " ") + " | " + LocalizeText(props.texts, 'global.hotelname')
    sendMessage(JSON.stringify(new ArticleComposer(getArticleIdByParam(params.id))))
    sendMessage(JSON.stringify(new NewsComposer()))
  }, [])

  useEffect(() => {
    if (lastMessage !== null) {
      var message = JSON.parse(lastMessage.data);

      if (message.header === "newdata" && message.data !== null) {
        if (message.data.new === null) navigate("/articles", { replace: true });
        setArticle(JSON.parse(message.data.new));
        setComments(JSON.parse(message.data.comments));
      }
      if (message.header === "newsList" && message.data !== null) setNews(JSON.parse(message.data.data));
      if (message.header === "CommentResponse" && message.data !== null) {
        if (message.data.data === "Esta noticia no existe") NotificationManager.error(LocalizeText(props.texts, 'article.no.found'), "Error")
        else if (message.data.data === "Error") NotificationManager.error(LocalizeText(props.texts, 'article.error'), "Error")
        else if (message.data.data === "Comentario no valido") NotificationManager.error(LocalizeText(props.texts, 'article.comment.invalid'), "Error")
        else if (message.data.data === "No intentes hacer publicidad!") NotificationManager.error(LocalizeText(props.texts, 'article.comment.publi'), "Error")
        else if (message.data.data === "Algo salio mal") NotificationManager.error(LocalizeText(props.texts, 'article.error'), "Error")
        else NotificationManager.success(LocalizeText(props.texts, 'article.comment.success'), "OK")
      }
      if (message.header === "removeCommentResponse" && message.data !== null) {
        if (message.data.data === "Esta noticia no existe") NotificationManager.error(LocalizeText(props.texts, 'article.no.found'), "Error")
        else if (message.data.data === "Algo salio mal") NotificationManager.error(LocalizeText(props.texts, 'article.error'), "Error")
        else NotificationManager.success(LocalizeText(props.texts, 'article.comment.remove'), "OK")
      }
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    if (news.length !== 0 && article !== null) {
      setLoading(false);
    }
  }, [article, news])

  const changeNotice = (id) => {
    setArticle(null);
    setLoading(true);
    sendMessage(JSON.stringify(new ArticleComposer(id)))
  }

  const createComment = () => {
    if (comment === "") {
      NotificationManager.error(LocalizeText(props.texts, 'article.comment.notext'), "Error");
      return;
    }

    sendMessage(JSON.stringify(new ArticleCommentComposer(article.id, comment)));
    sendMessage(JSON.stringify(new ArticleComposer(article.id)))
  }

  const deleteComment = (id) => {
    sendMessage(JSON.stringify(new DeleteArticleCommentComposer(article.id, id)))
    sendMessage(JSON.stringify(new ArticleComposer(article.id)))
  }

  return (
    <>
      {loading ? <LoadingView /> : (
        <>
          {article !== null &&
            <div className='max-w-6xl w-full gap-4 mx-auto grid grid-cols-12 animate__animated animate__fadeIn animate__slower mt-5 px-2 md:px-0'>
              <div className="col-span-12 p-1 bg-[#f9f9f9]">
                <div className="h-32 bg-white whiteBackground flex flex-col items-center justify-center relative overflow-hidden">
                  <div className="newsImageLeft hidden md:block"></div>
                  <p className='text-4xl uppercase poppins-bold text-quarternary tracking-widest mt-2 text-center md:text-start'>Habbo Neuigkeiten</p>
                  <div className="w-96 h-[2px] bg-black"></div>
                  <p className='text-quarternary poppins-bold text-center md:text-start'><span className='text-[#5aa488]'>WAS PASSIERT</span> ZURZEIT IM HABBO HOTEL?</p>
                </div>
              </div>
              <div className="col-span-12 md:col-span-4 flex flex-col gap-2">
                <div className="flex flex-col gap-2 bg-[#fcfcfc]">
                  <div className="flex items-center gap-1 py-2 px-4">
                    <div className="newsImageIcon"></div>
                    <div className="text-quarternary poppins-bold">Letzte Neuigkeiten</div>
                  </div>
                  <hr className='m-0 -mt-2' />
                  {news[0] != null &&
                    <div className="p-3 -mt-2 hover:bg-quarternary transition ease-in-out duration-300 group flex gap-2 items-center relative">
                      <p className='text-base text-quarternary group-hover:text-white duration-300 transition flex items-center gap-3 group-hover:poppins-bold'>
                        <div className="opacity-0 pointer-events-none group-hover:opacity-100 -mr-1 -ml-1">
                          »
                        </div>
                        <Link onClick={() => changeNotice(news[0].id)} to={getArticleRoute(news[0].id, news[0].title)} style={{ textDecoration: "none" }}>{news[0].title}</Link>
                      </p>
                    </div>
                  }
                  <hr className='m-0 -mt-2' />
                  {news[1] != null &&
                    <div className="p-3 -mt-2 hover:bg-quarternary transition ease-in-out duration-300 group flex gap-2 items-center">
                      <p className='text-base text-quarternary group-hover:text-white duration-300 transition flex items-center gap-3 group-hover:poppins-bold'>
                        <div className="opacity-0 pointer-events-none group-hover:opacity-100 -mr-1 -ml-1">
                          »
                        </div>
                        <Link onClick={() => changeNotice(news[1].id)} to={getArticleRoute(news[1].id, news[1].title)} style={{ textDecoration: "none" }}>{news[1].title}</Link>
                      </p>
                    </div>
                  }
                  <hr className='m-0 -mt-2' />
                  {news[2] != null &&
                    <div className="p-3 -mt-2 hover:bg-quarternary transition ease-in-out duration-300 group flex gap-2 items-center">
                      <p className='text-base text-quarternary group-hover:text-white duration-300 transition flex items-center gap-3 group-hover:poppins-bold'>
                        <div className="opacity-0 pointer-events-none group-hover:opacity-100 -mr-1 -ml-1">
                          »
                        </div>
                        <Link onClick={() => changeNotice(news[2].id)} to={getArticleRoute(news[2].id, news[2].title)} style={{ textDecoration: "none" }}>{news[2].title}</Link>
                      </p>
                    </div>
                  }
                  <hr className='m-0 -mt-2' />
                  {news[3] != null &&
                    <div className="p-3 -mt-2 hover:bg-quarternary transition ease-in-out duration-300 group flex gap-2 items-center">
                      <div className="opacity-0 pointer-events-none group-hover:opacity-100 -mr-1 -ml-1">
                        »
                      </div>
                      <p className='text-base text-quarternary group-hover:text-white duration-300 transition flex items-center gap-3 group-hover:poppins-bold'>
                        <Link onClick={() => changeNotice(news[3].id)} to={getArticleRoute(news[3].id, news[3].title)} style={{ textDecoration: "none" }}>{news[3].title}</Link>
                      </p>
                    </div>
                  }
                  <hr className='m-0 -mt-2' />
                  {news[4] != null &&
                    <div className="p-3 -mt-2 hover:bg-quarternary transition ease-in-out duration-300 group flex gap-2 items-center">

                      <p className='text-base text-quarternary group-hover:text-white duration-300 transition flex items-center gap-3 group-hover:poppins-bold'>
                        <div className="opacity-0 pointer-events-none group-hover:opacity-100 -mr-1 -ml-1">
                          »
                        </div>
                        <Link onClick={() => changeNotice(news[4].id)} to={getArticleRoute(news[4].id, news[4].title)} style={{ textDecoration: "none" }}>{news[4].title}</Link>
                      </p>
                    </div>
                  }
                </div>
              </div>
              <div className="col-span-12 md:col-span-8">
                <div className="w-full flex flex-col bg-[#fcfcfc]">
                  <div className="flex w-full justify-between p-4 flex-col">
                    <div className="flex items-center justify-between">
                      <p className='text-quarternary text-lg poppins-bold'>{article.title}</p>
                      <p className='text-quarternary text-sm'>{timeSince(article.date)}</p>
                    </div>
                  <hr className='m-0 border-[1px] mt-2 border-opacity-70 border-quarternary' />
                  <div className="flex items-center justify-between mt-0.5">
                      <p className='text-quarternary text-sm'>{article.shortStory}</p>
                      <p className='text-quarternary text-sm'>Geschrieben von {article.author.username}</p>
                  </div>
                  </div>
                  <div className="card-body -mt-6 px-1.5" style={{ overflowX: "auto" }}>
                    <div id="articleContent" style={{ whiteSpace: "pre-wrap" }} className="text-quarternary p-3" dangerouslySetInnerHTML={{ __html: article.fullStory }} />
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      )}
    </>
  )
}

export default ArticleView
