import React, { useEffect, useState } from 'react'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { NotificationManager } from 'react-notifications';
import { Link, useNavigate } from 'react-router-dom';

import "./Register.scss";
import { RegisterComposer } from '../../packets/RegisterComposer';
import { LocalizeText } from '../../Globals';

interface RegisterNewProps {
  texts: { [key: string]: string };
  account: any;
}

const RegisterNewView = (props: RegisterNewProps) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [socketUrl, setSocketUrl] = useState("wss://ws.habbo.al:2087");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });

  const [avatar, setAvatar] = useState("ch-210-ffc200.sh-290-92.hd-180-f0b879.hr-100-91.lg-270-4a76aa");
  const [genre, setGenre] = useState("M");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    document.title = LocalizeText(props.texts, 'register.title') + " | Habbo"
  }, [])

  useEffect(() => {
    if (lastMessage !== null) {
      var message = JSON.parse(lastMessage.data);
      if (message.header === "registerResponse" && message.data != null) {
        if (message.data.data === "Este usuario ya existe") NotificationManager.error(LocalizeText(props.texts, 'register.user.invalid'), "Error");
        else if (message.data.data === "Este correo ya existe") NotificationManager.error(LocalizeText(props.texts, 'register.email.invalid'), "Error");
        else if (message.data.data === "La contraseña debe ser mayor a 6 digitos.") NotificationManager.error(LocalizeText(props.texts, 'register.password.invalid'), "Error");
        else if (message.data.data === "Ocurrio un error al crear la cuenta.") NotificationManager.error(LocalizeText(props.texts, 'register.error'), "Error");
        else {
          NotificationManager.success(LocalizeText(props.texts, 'register.success'), "OK");
          window.localStorage.setItem("kubboch-sto", message.data.data);
          navigate("/me", { replace: true });
        }
      }
    }
  }, [lastMessage, setMessageHistory]);

  const chooseAvatar = (av, genre) => {
    setAvatar(av);
    setGenre(genre);

    document.getElementById("firstStep")?.classList.add("hide-content");
    document.getElementById("secondStep")?.classList.remove("hide-content");
  }

  const chooseAccountData = () => {
    if (username === "" || email === "") {
      NotificationManager.error(LocalizeText(props.texts, 'register.checkinput1'), "Error");
      return;
    }

    document.getElementById("secondStep")?.classList.add("hide-content");
    document.getElementById("thirdStep")?.classList.remove("hide-content");
  }

  const getImagerDance = () => {
    return "https://nitro-imager.habbo.cm/?figure=" + avatar + "&direction=2&head_direction=2&size=l&dance=1&img_format=gif"
  }

  const getImagerNave = () => {
    return "https://nitro-imager.habbo.cm/?figure=" + avatar + "&direction=2&head_direction=2&size=l&effect=3&img_format=gif"
  }

  const register = () => {
    if (username === "" || email === "" || password === "" || confirmPassword === "") {
      NotificationManager.error(LocalizeText(props.texts, 'register.checkinput1'), "Error");
      return;
    }

    if (username.includes(" ")) {
      NotificationManager.error(LocalizeText(props.texts, 'register.checkinput2'), "Error");
      return;
    }

    if (password !== confirmPassword) {
      NotificationManager.error(LocalizeText(props.texts, 'register.checkinput3'), "Error");
      return;
    }

    sendMessage(JSON.stringify(new RegisterComposer(username, email, password, avatar, genre)));
  }

  useEffect(() => {
    if(props.account !== null) navigate("/me", { replace: true });
  }, [props]);

  return (
    <div className='min-h-screen w-full flex items-center justify-center'>

    <div className="grid grid-cols-12 max-w-7xl w-full gap-3 p-2 mt-2">
        <div className="col-span-12 p-2 h-auto md:h-12 relative bg-quarternary flex items-center">
            <div className="indexFrankImage hidden md:block absolute bottom-0"></div>
            <div className='md:px-20 flex flex-wrap md:flex-nowrap text-center md:text-start items-center gap-2'>
                <p className='text-center md:text-start w-full md:w-auto relative top-2 md:top-0'>***WILKOMMEN***</p>
                 <p className='text-white text-opacity-80 font-extralight'>Hallo lieber Besucher, registriere dich heute noch im Habbo Hotel und erlebe unsere Welt!</p>
            </div>
        </div>
        <div className="col-span-12 md:col-span-4 hidden md:flex flex-col drop-shadow-2xl">
            <div className="h-full indexPhoto relative flex justify-center items-center">
                <div className="absolute w-full h-full bg-black bg-opacity-50"></div>
                    <div className="HabboLogo flex justify-center items-center relative">
                        <div className="absolute -bottom-12 px-5 py-2 text-sm bg-quarternary rounded-lg flex items-center gap-1 bg-opacity-80">23 Spieler Online!</div>
                    </div>
                    <div className="absolute bottom-0 p-3 w-full bg-quarternary bg-opacity-70 flex flex-col">
                    <p className="text-2xl text-center">Wilkommen im HabboAL</p>
                    <p className="text-sm text-white text-opacity-70 text-center">Habbo ist eine virtuelle Welt für Kinder und Jugendliche. Hier bei uns kannst du eigene Raume erstellen!</p>
                </div>
            </div>
        </div>
        <div className="col-span-12 md:col-span-8 p-2 bg-quarternary flex flex-col drop-shadow-2xl">
          <div className="HabboLogo mx-auto block md:hidden"></div>
            <p className='text-xl text-center mt-1 mb-2'>Registrieren Hotel</p>
            <div className="flex flex-col gap-4 px-5 mt-2">
                <div className="flex  flex-wrap md:flex-nowrap  items-center gap-4">
                  <div className="flex flex-col gap-1 w-full">
                      <p>Benutzername</p>
                      <input onChange={(e) => setUsername(e.target.value)} className='newInput !border-l-4 !border-orange-400' type="text" placeholder='Benutzername' />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                      <p>E-Mail Adresse</p>
                      <input onChange={(e) => setEmail(e.target.value)} className='newInput !border-l-4 !border-orange-400' type="email" placeholder='E-Mail' />
                  </div>
                </div>
                <div className="flex  flex-wrap md:flex-nowrap  items-center gap-4">
                  <div className="flex flex-col gap-1 w-full">
                      <p>Passwort</p>
                      <input onChange={(e) => setPassword(e.target.value)} className='newInput !border-l-4 !border-orange-400' type="password" placeholder='Passwort' />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                      <p>Passwort wiederholen</p>
                      <input onChange={(e) => setConfirmPassword(e.target.value)} className='newInput !border-l-4 !border-orange-400' type="password" placeholder='Passwort wiederholen' />
                  </div>
                </div>
                <button onClick={() => register()} className='alBtn alBtn-client rounded-sm'>Registrieren</button>
                <span className='text-center -mb-2 -mt-2 text-sm text-white text-opacity-70'>oder</span>
                <Link className='alBtn alBtn-danger rounded-sm mb-2 text-center' to={'/index'}>Login</Link>
            </div>
        </div>
        <div className="col-span-12 md:col-span-4 bg-quarternary hidden md:flex items-center gap-3">
            <div className="max-w-[95px] min-w-[95px] max-h-[92px] min-h-[92px]"style={{background:"url(https://web.archive.org/web/20180304005246im_/http://live-hotel.biz/images/badgeomatic.png)"}}></div>
            <p className="text-sm">Erstelle einzigartige Badges mit unserem Badge-o-matic!</p>
        </div>
        <div className="col-span-12 md:col-span-4 bg-quarternary hidden md:flex items-center gap-3">
            <div className="max-w-[95px] min-w-[95px] max-h-[92px] min-h-[92px] !bg-center"style={{background:"url(https://web.archive.org/web/20180304005017im_/http://live-hotel.biz/images/wireds.png)"}}></div>
            <p className="text-sm">Baue spannende Events mit unseren Wireds, die alle zu 100% funktionieren!</p>
        </div>
        <div className="col-span-12 md:col-span-4 bg-quarternary hidden md:flex items-center gap-3">
            <div className="max-w-[95px] min-w-[95px] max-h-[92px] min-h-[92px]"style={{background:"url(https://web.archive.org/web/20180304005209im_/http://live-hotel.biz/images/stapelfelder.png)"}}></div>
            <p className="text-sm">Kreiere atemberaubende Räume mit unseren Stapelfeldern!</p>
        </div>
        <div className="col-span-12 flex flex-col items-center justify-center gap-1 relative top-4">
            <p className='text-white text-opacity-70 text-sm'>Copyright © 2024 HabboAL Hotel</p>
            <div className="flex items-center gap-2 text-white text-opacity-70 text-[11px]">
                <p>Impressum</p>
                <p>Regelwerk</p>
                <p>Kontaktiere Uns</p>
            </div>
        </div>
    </div>
    
</div>
  )
}

export default RegisterNewView
