class LoginInfo{
    username: string;
    password: string;

    constructor(username: string, password: string){
        this.username = username;
        this.password = password;
    }
}

export class LoginComposer{
    header: string;
    data: LoginInfo;

    constructor(username: string, password: string){
        this.header = "login";
        this.data = new LoginInfo(username, password);
    }
}